<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-6-tablet" v-for="(quantities, index) in dashboardTotalRecords" :key="`quantities-${index}`">
            <card-quantities
              :title="quantities.name"
              :icon="quantities.icon"
              :total="quantities.total"
              :primaryColor="quantities.color"
            />
          </div>
          <div class="column is-6">
            <card-product-list-most
              title="Productos más vendidos"
              :jsonData="dashboardBestSellers"
              filename="bestSellers"
            />
          </div>
          <div class="column is-6">
            <card-product-list-most
              title="Productos más cotizados"
              :jsonData="dashboardMostValued"
              filename="mostValued"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MainDashboard',
  components: {
    CardQuantities: () => import('@/components/Dashboard/CardQuantities.vue'),
    CardProductListMost: () => import('@/components/Dashboard/CardProductListMost.vue')
  },
  computed: {
    ...mapGetters(['dashboardTotalRecords', 'dashboardBestSellers', 'dashboardMostValued'])
  },
  data () {
    return {}
  },
  mounted () {
    this.getDashboardInfo()
  },
  methods: {
    ...mapActions(['getDashboardInfo'])
  }
}
</script>

<style lang="scss" scoped>
</style>
